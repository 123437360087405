export default [
  {
    path: '/audit-assigned',
    name: 'audit-assigned',
    component: () => import('@/views/audit-assigned/AuditAssigned.vue'),
    meta: {
      title: 'Audit Assigned ',
      requiresAuth: false,
      action: 'browes',
      resource: 'Audit Assigned',
      pageTitle: 'Audit Assigned',
      breadcrumb: [
        {
          text: 'Audit Assigned',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/assign-date-and-eng/:id',
    name: 'assign-date-and-eng',
    component: () => import(
      '@/views/audit-assigned/control-audit-assigned/AssignDateAndEng.vue'
    ),
    meta: {
      title: 'Assign Date And Eng ',
      requiresAuth: false,
      action: 'browes',
      resource: 'Assign Date And Eng',
      pageTitle: 'Assign Date And Eng',
      breadcrumb: [
        {
          text: 'Assign Date And Eng',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
]
