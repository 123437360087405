export default [
  {
    path: '/set-money',
    name: 'set-money',
    component: () => import('@/views/set-money/SetMoney.vue'),
    meta: {
      title: 'Set Money ',
      requiresAuth: false,
      action: 'browes',
      resource: 'Set Money',
      pageTitle: 'Set Money',
      breadcrumb: [
        {
          text: 'Set Money',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },

  {
    path: '/need-set-mony/:id',
    name: 'need-set-mony',
    component: () => import('@/views/set-money/control-set-money/NeedSetMony.vue'),
    meta: {
      title: 'Need Set Mony ',
      requiresAuth: false,
      action: 'create',
      resource: 'Need Set Mony',
      pageTitle: 'Need Set Mony',
      breadcrumb: [
        {
          text: 'Need Set Mony',
          to: '/set-money',
        },
        {
          text: 'Set Mony',
          active: true,
        },
      ],
    },
  },
]
