export default [

  {
    path: '/engineers-courses',
    name: 'engineers-courses',
    component: () => import('@/views/engineers-courses/EngineersCourses.vue'),
    meta: {
      title: 'EngineersCourses ',
      requiresAuth: false,
      action: 'browes',
      resource: 'Engineers Courses',
      pageTitle: 'Engineers Courses',
      breadcrumb: [
        {
          text: 'Engineers Courses',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/engineers-courses/add',
    name: 'add-engineers-courses',
    component: () => import('@/views/engineers-courses/control-engineers-courses/AddEditEngineersCourses.vue'),
    meta: {
      title: 'Add Engineers Courses ',
      requiresAuth: false,
      action: 'create',
      resource: 'Engineers Courses',
      pageTitle: 'Engineers Courses',
      breadcrumb: [
        {
          text: 'Engineers Courses',
          to: '/engineers-courses',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/engineers-courses/edit/:id',
    name: 'edit-engineers-courses',
    component: () => import('@/views/engineers-courses/control-engineers-courses/AddEditEngineersCourses.vue'),
    meta: {
      title: 'Edit Engineers Courses ',
      requiresAuth: false,
      pageTitle: 'Engineers Courses',
      breadcrumb: [
        {
          text: 'Engineers Courses',
          to: '/engineers-courses',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },

]
