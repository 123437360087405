export default [

  {
    path: '/user-panel',
    name: 'user-panel',
    component: () => import('@/views/user-panel/UserPanel.vue'),
    meta: {
      title: 'User Panel',
      requiresAuth: false,
      action: 'browes',
      resource: 'user-panel',
      pageTitle: 'User Panel',
      breadcrumb: [
        {
          text: 'User Panel',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user-panel/add',
    name: 'add-user-panel',
    component: () => import('@/views/user-panel/control-user-panel/AddEditUserPanel.vue'),
    meta: {
      title: 'Add User Panel ',
      requiresAuth: false,
      action: 'create',
      resource: 'user-panel',
      pageTitle: 'User Panel',
      breadcrumb: [
        {
          text: 'User Panel',
          to: '/user-panel',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user-panel/:id',
    name: 'edit-user-panel',
    component: () => import('@/views/user-panel/control-user-panel/AddEditUserPanel.vue'),
    meta: {
      title: 'Edit User Panel ',
      requiresAuth: false,
      pageTitle: 'User Panel',
      breadcrumb: [
        {
          text: 'User Panel',
          to: '/user-panel',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },

]
