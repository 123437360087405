export default [

  {
    path: '/mainCertificate/:MCR_id/certificate/:CM_id',
    name: 'components',
    component: () => import('@/views/main-certificate/certificate/components/Components.vue'),
    meta: {
      title: 'components ',
      requiresAuth: false,
      action: 'browes',
      resource: 'components',
      pageTitle: 'components',
      breadcrumb: [
        {
          text: 'components',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mainCertificate/:MCR_id/certificate/:CM_id/components/add',
    name: 'add-components',
    component: () => import('@/views/main-certificate/certificate/components/control-components/AddEditComponents.vue'),
    meta: {
      title: 'Add components ',
      requiresAuth: false,
      action: 'create',
      resource: 'components',
      pageTitle: 'components',
      breadcrumb: [
        {
          text: 'components',
          to: '/components',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mainCertificate/:MCR_id/certificate/:CM_id/components/:id/edit',
    name: 'edit-components',
    component: () => import('@/views/main-certificate/certificate/components/control-components/AddEditComponents.vue'),
    meta: {
      title: 'Edit components ',
      requiresAuth: false,
      pageTitle: 'components',
      breadcrumb: [
        {
          text: 'components',
          to: '/components',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },

]
