export default [

  {
    path: '/mainCertificate/:MCR_id/certificate/:CM_id/components/:SE_id/section/:RQ_id/requirement',
    name: 'requirements',
    component: () => import('@/views/main-certificate//certificate/components/section/requirements/Requirements.vue'),
    meta: {
      title: 'requirements ',
      requiresAuth: false,
      action: 'browes',
      resource: 'requirements',
      pageTitle: 'requirements',
      breadcrumb: [
        {
          text: 'requirements',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mainCertificate/:MCR_id/certificate/:CM_id/components/:SE_id/section/:RQ_id/requirement/add',
    name: 'add-requirements',
    component: () => import('@/views/main-certificate/certificate/components/section/requirements/control-requirements/AddEditRequirements.vue'),
    meta: {
      title: 'Add requirements ',
      requiresAuth: false,
      action: 'create',
      resource: 'requirements',
      pageTitle: 'requirements',
      breadcrumb: [
        {
          text: 'requirements',
          to: '/requirements',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mainCertificate/:MCR_id/certificate/:CM_id/components/:SE_id/section/:RQ_id/requirement/:id',
    name: 'edit-requirements',
    component: () => import('@/views/main-certificate/certificate/components/section/requirements/control-requirements/AddEditRequirements.vue'),
    meta: {
      title: 'Edit requirements ',
      requiresAuth: false,
      pageTitle: 'requirements',
      breadcrumb: [
        {
          text: 'requirements',
          to: '/requirements',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },

]
