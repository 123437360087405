export default [
  {
    path: '/service-need-set-money',
    name: 'service-need-set-money',
    component: () => import('@/views/service-need-set-money/ServiceNeedSetMoney.vue'),
    meta: {
      title: 'Service Need Set Money',
      requiresAuth: false,
      action: 'browes',
      resource: 'Service Need Set Money',
      pageTitle: 'Service Need Set Money',
      breadcrumb: [
        {
          text: 'Service Need Set Money',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/set-money/:id',
    name: 'service-set-money',
    component: () => import(
      '@/views/service-need-set-money/control-service-need-set-money/ServiceSetEstimateMoney.vue'
    ),
    meta: {
      title: 'Service Set Money',
      requiresAuth: false,
      action: 'create',
      resource: 'service-need-approval',
      pageTitle: 'Service Set Money',
      breadcrumb: [
        {
          text: 'Service Need Set Money',
          to: '/service-need-set-money',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/cities/edit/:id',
  //   name: 'edit-city',
  //   component: () => import('@/views/cities/control-city/AddEditCity.vue'),
  //   meta: {
  //     title: 'Edit City ',
  //     requiresAuth: false,
  //     pageTitle: 'Cities',
  //     breadcrumb: [
  //       {
  //         text: 'Cities',
  //         to: '/cities',
  //       },
  //       {
  //         text: 'Edit',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]
