export default [
  {
    path: '/all-service',
    name: 'all-service',
    component: () => import('@/views/all-service/AllService.vue'),
    meta: {
      title: 'Service',
      requiresAuth: false,
      action: 'browes',
      resource: 'Service',
      pageTitle: 'Service',
      breadcrumb: [
        {
          text: 'Service',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/service-show/:id',
    name: 'service-show',
    component: () => import('@/views/all-service/control-all-service/InfoService.vue'),
    meta: {
      title: 'Info Service',
      requiresAuth: false,
      action: 'create',
      resource: 'Info Service',
      pageTitle: 'Info Service',
      breadcrumb: [
        {
          text: 'Info Service',
          to: '/all-service',
        },
        {
          text: 'Show',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/cities/edit/:id',
  //   name: 'edit-city',
  //   component: () => import('@/views/cities/control-city/AddEditCity.vue'),
  //   meta: {
  //     title: 'Edit City ',
  //     requiresAuth: false,
  //     pageTitle: 'Cities',
  //     breadcrumb: [
  //       {
  //         text: 'Cities',
  //         to: '/cities',
  //       },
  //       {
  //         text: 'Edit',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]
