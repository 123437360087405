export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Home.vue'),
    meta: {
      title: 'Dashboard - System Gulftic',
      requiresAuth: false,
    },
  },

  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('@/views/notifications/Notifications.vue'),
    meta: {
      title: 'Notifications - System Gulftic',
      requiresAuth: false,
      action: 'browse',
      pageTitle: 'Notifications',
      breadcrumb: [
        {
          text: 'Notifications',
          active: true,
        },
      ],
    },
  },

  {
    path: '/user-profile',
    name: 'user-profile',
    component: () => import('@/views/user-profile/UserProfile.vue'),
    meta: {
      title: 'User Settings - System Gulftic',
      requiresAuth: false,
      action: 'browes',
      resource: 'dashboard',
      pageTitle: 'Account Settings',
      breadcrumb: [
        {
          text: 'Account Settings',
          active: true,
        },
      ],
    },
  },

  {
    path: '/social-contacts',
    name: 'social-contacts',
    component: () => import('@/views/social-contacts/SocialContacts.vue'),
    meta: {
      title: 'Social Contacts',
      requiresAuth: false,
      pageTitle: 'Social Contacts',
      action: 'browes',
      breadcrumb: [
        {
          text: 'Social Contacts',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app-version',
    name: 'app-version',
    component: () => import('@/views/app-version/AppVersion.vue'),
    meta: {
      title: 'App Version',
      requiresAuth: false,
      pageTitle: 'App Version',
      action: 'browes',
      breadcrumb: [
        {
          text: 'App Version',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app-data',
    name: 'app-data',
    component: () => import('@/views/app-data/AppData.vue'),
    meta: {
      title: 'App Data',
      requiresAuth: false,
      pageTitle: 'App Data',
      action: 'browes',
      breadcrumb: [
        {
          text: 'App Data',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user-messages',
    name: 'user-messages',
    component: () => import('@/views/user-messages/UserMessages.vue'),
    meta: {
      title: 'User Messages',
      requiresAuth: false,
      pageTitle: 'User Messages',
      action: 'browes',
      breadcrumb: [
        {
          text: 'User Messages',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user-messages/view/:id',
    name: 'view-user-msg',
    component: () => import('@/views/user-messages/ViewUserMsg.vue'),
    meta: {
      title: 'User Messages',
      requiresAuth: false,
      pageTitle: 'User Messages',
      breadcrumb: [
        {
          text: 'User Messages',
          to: '/user-messages',
        },
        {
          text: 'view',
          active: true,
        },
      ],
    },
  },
]
