export default [

  {

    path: '/mainCertificate/:MCR_id/certificate/:CM_id/components/:SE_id/section',
    name: 'sections',
    component: () => import('@/views/main-certificate/certificate/components/section/Section.vue'),
    meta: {
      title: 'sections ',
      requiresAuth: false,
      action: 'browes',
      resource: 'sections',
      pageTitle: 'sections',
      breadcrumb: [
        {
          text: 'sections',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mainCertificate/:MCR_id/certificate/:CM_id/components/:SE_id/section/add',
    name: 'add-sections',
    component: () => import('@/views/main-certificate/certificate/components/section/control-section/AddEditSection.vue'),
    meta: {
      title: 'Add sections ',
      requiresAuth: false,
      action: 'create',
      resource: 'sections',
      pageTitle: 'sections',
      breadcrumb: [
        {
          text: 'sections',
          to: '/sections',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mainCertificate/:MCR_id/certificate/:CM_id/components/:SE_id/section/edit/:id',
    name: 'edit-sections',
    component: () => import('@/views/main-certificate/certificate/components/section/control-section/AddEditSection.vue'),
    meta: {
      title: 'Edit sections ',
      requiresAuth: false,
      pageTitle: 'sections',
      breadcrumb: [
        {
          text: 'sections',
          to: '/sections',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },

]
