export default [

  {
    path: '/offer',
    name: 'offer',
    component: () => import('@/views/offer/Offer.vue'),
    meta: {
      title: 'Cities ',
      requiresAuth: false,
      action: 'browes',
      resource: 'Offer',
      pageTitle: 'Offer',
      breadcrumb: [
        {
          text: 'Offer',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/offer/add',
    name: 'add-offer',
    component: () => import('@/views/offer/control-offer/AddEditOffer.vue'),
    meta: {
      title: 'Add Offer ',
      requiresAuth: false,
      action: 'create',
      resource: 'offer',
      pageTitle: 'Offer',
      breadcrumb: [
        {
          text: 'Offer',
          to: '/offer',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/offer/edit/:id',
    name: 'edit-offer',
    component: () => import('@/views/offer/control-offer/AddEditOffer.vue'),
    meta: {
      title: 'Edit Offer ',
      requiresAuth: false,
      pageTitle: 'Offer',
      breadcrumb: [
        {
          text: 'Offer',
          to: '/offer',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },

]
