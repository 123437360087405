export default [

  {
    path: '/mainCertificate',
    name: 'main-certificate',
    component: () => import('@/views/main-certificate/MainCertificate.vue'),
    meta: {
      title: 'certificate ',
      requiresAuth: false,
      action: 'browes',
      resource: 'certificate',
      pageTitle: 'certificate',
      breadcrumb: [
        {
          text: 'certificate',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mainCertificate/add',
    name: 'add-main-certificate',
    component: () => import('@/views/main-certificate/control-main-certificate/AddEditMainCertificate.vue'),
    meta: {
      title: 'Add Certificate ',
      requiresAuth: false,
      action: 'create',
      resource: 'certificate',
      pageTitle: 'Certificate',
      breadcrumb: [
        {
          text: 'Certificate',
          to: '/certificate',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mainCertificate/edit/:id',
    name: 'edit-main-certificate',
    component: () => import('@/views/main-certificate/control-main-certificate/AddEditMainCertificate.vue'),
    meta: {
      title: 'Edit Certificate ',
      requiresAuth: false,
      pageTitle: 'Certificate',
      breadcrumb: [
        {
          text: 'Certificate',
          to: '/certificate',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },

]
