export default [

  {
    path: '/user-site',
    name: 'user-site',
    component: () => import('@/views/user-site/UserSite.vue'),
    meta: {
      title: 'User Site',
      requiresAuth: false,
      action: 'browes',
      resource: 'user-site',
      pageTitle: 'User Site',
      breadcrumb: [
        {
          text: 'User Site',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user-site/add',
    name: 'add-user-site',
    component: () => import('@/views/user-site/control-user-site/AddUserSite.vue'),
    meta: {
      title: 'Add User Site ',
      requiresAuth: false,
      action: 'create',
      resource: 'user-site',
      pageTitle: 'User Site',
      breadcrumb: [
        {
          text: 'User Site',
          to: '/user-Site',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user-site/:id',
    name: 'edit-user-site',
    component: () => import('@/views/user-site/control-user-site/AddUserSite.vue'),
    meta: {
      title: 'Edit User Site ',
      requiresAuth: false,
      pageTitle: 'User Site',
      breadcrumb: [
        {
          text: 'User Site',
          to: '/user-site',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },

]
